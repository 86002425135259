
export function IntegerView({
  value,
  prefix,
  className
}: {
  value: any
  prefix?: string
  className?: string
}): JSX.Element {
  let numVal = typeof value === 'string' ? parseInt(value) : value
  return (
    <span className={className || ''}>
      {numVal ? `${prefix || ''}${numVal.toLocaleString('en-us')}` : ''}
    </span>
  );
}
