export function EndemicityView({
  value
}: {
  value: any
}): JSX.Element {
  if (value === 'endemic_mda_stopped') {
    return (
      <span className="d-block text-decoration-line-through" title="Endemic, MDA stopped">
        MDA
      </span>
    );
  } else if (value === 'endemic_pending_impact_survey') {
    return (
      <span className="d-block" title="Endemic, pending impact survey">
        Yes (IS)
      </span>
    );
  } else if (value === 'status_unknown') {
    return (
      <span className="d-block" title="Status Unknown">
        ?
      </span>
    );
  } else if (value === 'endemic') {
    return (
      <span className="d-block" title="Endemic">
        Yes
      </span>
    );
  } else {
    return <span className="d-block" title={value}>&nbsp;</span>
  }
}