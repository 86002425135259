import {LoadablePane} from '../../components';
import {
  FileData,
  FormButtons,
  FormError,
  LabeledFileInput,
  LabeledInput,
  SafeForm
} from '../../components/form';
import {Form, Formik, FormikValues} from 'formik';
import {ApplicationApprovalDao, RequestDao} from '../../api';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAppContext} from '../../context';
import {inTryCatch} from '../../lib';

export function ApplicationApprovals({
  requestId
}: {
  requestId?: number
}): JSX.Element {

  const params = useParams()
  const application_id = +(params.id ?? 0)
  const { session } = useAppContext()

  const [approvals, setApprovals] = useState<FormikValues>()
  const [requestAmounts, setRequestAmounts] = useState<FormikValues>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>()
  const [forceReload, setForceReload] = useState(0)
  const [approvalLtrFile, setApprovalLtrFile] = useState<FileData>()
  const [approvalLtrEmailFile, setApprovalLtrEmailFile] = useState<FileData>()
  const [acknowledgementFile, setAcknowledgementFile] = useState<FileData>()


  useEffect(inTryCatch(setLoading, setError, async (state) => {
    let {rows, request} = await new ApplicationApprovalDao(session).get(application_id) ?? {}
    if (!rows) rows = {}
    if (!request) request = {}
    if (!state.mounted) return

    setApprovals(rows)
    setRequestAmounts(request)

    if (rows.approval_letter_file_id)
      setApprovalLtrFile({
        file_id: rows.approval_letter_file_id,
        name: rows.approval_letter_file_name,
        type: rows.approval_letter_mime_type,
        upload_date: rows.approval_letter_file_upload_date,
      })
    else
      setApprovalLtrFile(undefined)

    if (rows.approval_letter_email_file_id)
      setApprovalLtrEmailFile({
        file_id: rows.approval_letter_email_file_id,
        name: rows.approval_letter_email_file_name,
        type: rows.approval_letter_email_mime_type,
        upload_date: rows.approval_letter_email_file_upload_date,
      })
    else
      setApprovalLtrEmailFile(undefined)

    if (rows.acknowledgement_file_id)
      setAcknowledgementFile({
        file_id: rows.acknowledgement_file_id,
        name: rows.acknowledgement_file_name,
        type: rows.acknowledgement_mime_type,
        upload_date: rows.acknowledgement_file_upload_date,
      })
    else
      setAcknowledgementFile(undefined)

  }), [session, application_id, forceReload])

  const totalTreatmentsApproved = (+(approvals?.treatments_approved_lf ?? 0))
      + (+(approvals?.treatments_approved_oncho ?? 0))
      + (+(approvals?.treatments_approved_lf_oncho ?? 0))
      + (+(approvals?.treatments_approved_ida ?? 0))

  const totalTabletsNeeded = (+(approvals?.tablets_needed_lf ?? 0))
    + (+(approvals?.tablets_needed_oncho ?? 0))
    + (+(approvals?.tablets_needed_lf_oncho ?? 0))
    + (+(approvals?.tablets_needed_ida ?? 0))

  return (
    <div className="card-body">
      <LoadablePane loading={loading || !approvals}>

        <Formik
          initialValues={approvals!}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setError(undefined)
              await new ApplicationApprovalDao(session).save(values, application_id, approvalLtrFile, approvalLtrEmailFile, acknowledgementFile)
              setForceReload(p => p + 1)
              setSubmitting(false)
            } catch (e: any) {
              setError(e.message)
            }
          }}
        >
          {({ isSubmitting, dirty }) => (
            <SafeForm dirty={dirty} className="container">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <h5 className="col-md-8 offset-md-4">Treatments Requested</h5>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <h5 className="col-md-8 offset-md-4">Treatments Approved</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3 row">
                    <label className="col-md-4 text-md-end col-form-label">
                      LF
                    </label>
                    <div className="col-md-8">
                      <input className="form-control" readOnly 
                        value={parseInt(requestAmounts?.pc_req_ivm_lf_only || 0).toLocaleString('en-us')} 
                      />
                    </div>
                  </div>
                </div>
                <LabeledInput name="treatments_approved_lf" side integer className="col-6" label="LF" 
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3 row">
                    <label className="col-md-4 text-md-end col-form-label">
                      Oncho
                    </label>
                    <div className="col-md-8">
                      <input className="form-control" readOnly 
                        value={parseInt(requestAmounts?.pc_req_ivm_oncho_only || 0).toLocaleString('en-us')} 
                      />
                    </div>
                  </div>
                </div>
                <LabeledInput name="treatments_approved_oncho" side integer className="col-6" label="Oncho" 
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                 />
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3 row">
                    <label className="col-md-4 text-md-end col-form-label">
                      LF/Oncho
                    </label>
                    <div className="col-md-8">
                      <input className="form-control" readOnly 
                        value={parseInt(requestAmounts?.pc_req_ivm_lf_oncho || 0).toLocaleString('en-us')} 
                      />
                    </div>
                  </div>
                </div>
                <LabeledInput name="treatments_approved_lf_oncho" side integer className="col-6" label="LF/Oncho"  
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3 row">
                    <label className="col-md-4 text-md-end col-form-label">
                      IDA
                    </label>
                    <div className="col-md-8">
                      <input className="form-control" readOnly 
                        value={parseInt(requestAmounts?.pc_req_ivm_ida || 0).toLocaleString('en-us')} 
                      />
                    </div>
                  </div>
                </div>
                <LabeledInput name="treatments_approved_ida" side integer className="col-6" label="IDA"  
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-sm-4 fw-bold text-md-end">Total</div>
                    <div className="col-sm-8 fw-bold">
                      {parseInt(requestAmounts?.pc_req_ivm_total || 0).toLocaleString('en-us')}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-sm-4 fw-bold text-md-end">Total</div>
                    <div className="col-sm-8 fw-bold">
                      {(totalTreatmentsApproved || 0).toLocaleString('en-us')}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <h5 className="col">Tablets</h5>
              </div>
              <div className="row">
                <LabeledInput name="tablets_leftover_in_stock" className="col-md-8" side integer label="Leftover in stock"  
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                />
              </div>
              <div className="row">
                <LabeledInput name="tablets_approved" className="col-md-8" side integer label="Approved to ship" 
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"/>
              </div>
              <div className="row">
                <LabeledInput name="tablets_shipped" className="col-md-8" side integer label="Shipped" 
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"/>
              </div>
              <hr />
              <div className="row">
                <h5 className="col">Approval Files</h5>
              </div>
              <div className="row">
                <LabeledFileInput value={approvalLtrFile} onChange={setApprovalLtrFile} 
                  className="col-12 mb-2" side label="Approval Letter" 
                  labelClass="col-md-3 fw-bold text-md-end me-2" fieldClass="col-md-9"
                />
              </div>
              <div className="row">
                <LabeledFileInput value={approvalLtrEmailFile} onChange={setApprovalLtrEmailFile} 
                  className="col-12 mb-2" side label="Approval Letter Email"
                  labelClass="col-md-3 fw-bold text-md-end me-2" fieldClass="col-md-9"
                />
              </div>
              <div className="row">
                <LabeledFileInput value={acknowledgementFile} onChange={setAcknowledgementFile} 
                  className="col-12 mb-2" side label="Acknowledgement"
                  labelClass="col-md-3 fw-bold text-md-end me-2" fieldClass="col-md-9"
                />
              </div>
              <hr />
              <FormButtons className="col-12 mt-2"
                 buttons={[
                   {
                     label: 'Submit',
                     disabled: isSubmitting || loading,
                     submitting: isSubmitting
                   }, {
                     label: 'Cancel',
                     type: 'button',
                     className: 'btn btn-default',
                     onClick: () => setForceReload(p => p + 1),
                     disabled: isSubmitting || loading
                   }
                 ]}
              />
            </SafeForm>
          )}
        </Formik>
      </LoadablePane>
      <FormError error={error} />
    </div>
  )
}
