import { ReactNode } from 'react';

import { useAppContext } from '../context'
import {Link} from 'react-router-dom';

export default function Container({
  title,
  active,
  children,
}: {
  title?: string
  active?: string
  children: ReactNode
}): JSX.Element {

  const { session, logout } = useAppContext()

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
          { session?.user ? (
            <>
                <Link to="/" className="navbar-brand">{title || ""}</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link to="/" 
                        className={`nav-link ${active === 'applications' ? 'active' : ''}`} 
                      >
                        Applications
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/shipments" 
                        className={`nav-link ${active === 'shipments' ? 'active' : ''}`} 
                      >
                        Shipments
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/districts" 
                        className={`nav-link ${active === 'districts' ? 'active' : ''}`} 
                      >
                        Districts
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {session.user}
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#" onClick={logout}>Sign Out</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <Link to="/applications/" className="btn btn-primary">New Application</Link>
              </>)
            : (
              <a href="/" className="navbar-brand">{title || ""}</a>
            )
          }
        </div>
      </nav>
      <div className="container-fluid content-container mt-3 mb-5">
        {children}
      </div>
    </div>
  )
}
