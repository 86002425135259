import {QueryApplicationsFilters, PagingParams, SortingInfo} from '../api';
import {ChangeEvent, KeyboardEvent, useState, useEffect} from 'react';
import {
  SortAlphaDown, 
  SortAlphaUpAlt, 
  SortNumericDown, 
  SortNumericUpAlt,
  FilterIcon
} from '../components/icons';


export interface GenericSearchTableHeaderParams<T> {
  title: string
  prop: Extract<keyof T, string>
  filter?: any
  setFilter?: (prop: Extract<keyof T, string>, val: string) => void
  paging: PagingParams
  changeSort: (prop: string) => void
  sortInfo: SortingInfo
  colSpan?: number
  rowSpan?: number
}


export function GenericSearchTableHeader<T>(
  {
    title,
    prop,
    filter,
    setFilter,
    paging,
    changeSort,
    sortInfo,
    colSpan,
    rowSpan
  } : GenericSearchTableHeaderParams<T>
): JSX.Element {

  const [text, setText] = useState(filter ? filter[prop] || '' : '')

  function onKeyUp(key: KeyboardEvent<HTMLInputElement>) {
    if (key.key === 'Enter' && setFilter) {
      setFilter(prop, text)
    }
  }

  const sortElement: JSX.Element = paging.sort === prop
    ? (
      sortInfo[prop].dataType === 'alpha'
        ? paging.order === 'asc' ? <SortAlphaDown /> : <SortAlphaUpAlt />
        : paging.order === 'asc' ? <SortNumericDown /> : <SortNumericUpAlt />
    ) : <></>

  // @ts-ignore
  const filterIndicator = filter && filter[prop] && filter[prop].length > 0 && <FilterIcon />

  useEffect(() => {
    if (filter)
      setText(filter[prop] || '')
  }, [filter])

  return (
    <th scope="col"
      className="GenericSearchTableHeader"
      onClick={() => changeSort(prop)}
      colSpan={colSpan}
      rowSpan={rowSpan}
    >
      <div className="table-col-title pb-2" 
        role="button"
        title="Click to sort"
      >
        {title}
        &nbsp;
        {filterIndicator}
        {sortElement}
      </div>
      {filter && setFilter &&
        <div className="input-group">
          <input
            className="form-control form-control-sm"
            onKeyUp={e => onKeyUp(e)}
            onClick={(e) => { e.stopPropagation() }}
            value={text}
            onChange={e => setText(e.target.value)}
            />
          { text != '' && filter &&
            <button className="btn btn-sm btn-outline-secondary" type="button"
              onClick={() => setFilter(prop, '')}
            >
              Clear
            </button>
          }
        </div>
      }
    </th>
  )
}
