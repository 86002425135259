import {useEffect, useMemo, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {
  ShipmentDao,
  PagingParams, 
  QueryShipmentsFilters, 
  DbResponseResultSet, 
  ShipmentSortInfo,
  DbLiteralTypes,
  ShipmentStatus
} from '../api';
import {useAppContext} from '../context';
import {inTryCatch} from '../lib';
import {
  ApplicationStatus,
  DangerAlert,
  GenericSearchTableHeader,
  GenericSelectTableHeader, 
  GenericSelectTableHeaderParams,
  Pager,
  MonthView,
  IntegerView
} from '../components';


function SelectTableHeader(p: GenericSelectTableHeaderParams<QueryShipmentsFilters>) { return GenericSelectTableHeader<QueryShipmentsFilters>(p)}

export function Shipments(): JSX.Element {

  const [searchParams, setSearchParams] = useSearchParams()
  const {session, logout} = useAppContext()

  const [shipments, setShipments] = useState<DbResponseResultSet | undefined>()
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [error, showError] = useState<string>()

  const {paging, filter} = useMemo(() => {
    const paging: PagingParams = {
      limit: 50,
      offset: 0,
      sort: 'request_year',
      order: 'desc',
    }

    if (searchParams.get('limit')) paging.limit = parseInt(searchParams.get('limit') ?? '', 10)
    if (searchParams.get('offset')) paging.offset = parseInt(searchParams.get('offset') ?? '', 10)
    if (searchParams.get('sort')) paging.sort = searchParams.get('sort') ?? 'app_id'
    if (searchParams.get('order')) paging.order = searchParams.get('order') === 'asc' ? 'asc' : 'desc'

    const filter: QueryShipmentsFilters = {
      app_id: '',
      country_name: '',
      request_year: '',
      status: '',
      scope: ''
    }

    if (searchParams.get('application_id')) filter.app_id = searchParams.get('app_id') ?? ''
    if (searchParams.get('app_id')) filter.app_id = searchParams.get('app_id') ?? ''
    if (searchParams.get('country_name')) filter.country_name = searchParams.get('country_name') ?? ''
    if (searchParams.get('request_year')) filter.request_year = searchParams.get('request_year') ?? ''
    if (searchParams.get('status')) filter.status = searchParams.get('status') ?? ''
    if (searchParams.get('donee_name')) filter.donee_name = searchParams.get('donee_name') ?? ''
    if (searchParams.get('shipper_name')) filter.shipper_name = searchParams.get('shipper_name') ?? ''
    if (searchParams.get('scope')) filter.scope = searchParams.get('scope') ?? ''

    return {paging, filter}
  }, [searchParams])

  function setOffset(offset: number) {
    setSearchParams(prev => {
      prev.set("offset", offset.toString())
      return prev
    }, {replace: true})
  }

  function changeFilter(prop: keyof QueryShipmentsFilters, val: string) {
    setSearchParams(prev => {
      prev.set(prop, val)
      prev.set('offset', '0')
      return prev
    }, {replace: true})
  }

  function changeSort(prop: string) {
    const newDirection = paging.sort === prop
      ? (
        paging.order === 'asc' ? 'desc' : 'asc'
      ) : 'asc'

    setSearchParams(prev => {
      prev.set('order', newDirection)
      prev.set('sort', prop)
      prev.set('offset', '0')
      return prev
    }, {replace: true})
  }

  function setScope(scope: string) {
    setSearchParams(prev => {
      prev.set("scope", scope)
      return prev
    }, {replace: true})
  }

  useEffect(inTryCatch(setIsLoading, showError, async(state) => {
      const shipmentsInfo = await new ShipmentDao(session).query(filter, paging)
      if (!state.mounted) return
      setShipments(shipmentsInfo.rows)
      setCount(shipmentsInfo.count)
    }), [paging, filter, session])

  return (
    <>
      <DangerAlert text={error} />
      <div className="row mb-2">
        <div className="col">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button className={`nav-link ${!searchParams.get('scope') || searchParams.get('scope') === '' ? 'active' : ''}`}
                onClick={() => setScope('')}
                title="Any shipments not delivered"
              >
                In Progress / Open
              </button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${searchParams.get('scope') === 'all' ? 'active' : ''}`}
                onClick={() => setScope('all')}
                title="All shipments"
              >
                All
              </button>
            </li>
          </ul>
        </div>
        <div className="col-md-auto text-md-end">
          <Pager count={count} pager={paging} setOffset={setOffset} />
        </div>
      </div>
      <div className="row">
        <div className="col overflow-auto">
        <table className="table table-striped table-sm table-bordered">
          <thead>
            <tr>
              <GenericSearchTableHeader 
                rowSpan={2}
                title="Application ID" 
                prop='app_id'
                filter={filter}
                setFilter={changeFilter} 
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader
                rowSpan={2}
                title="Country" 
                prop='country_name' 
                filter={filter}
                setFilter={changeFilter} 
                paging={paging} 
                changeSort={changeSort} 
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                rowSpan={2}
                title="Request Year" 
                prop='request_year' 
                filter={filter}
                setFilter={changeFilter} 
                paging={paging} 
                changeSort={changeSort} 
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                rowSpan={2}
                title="Application Status" 
                prop='application_status' 
                filter={filter}
                setFilter={changeFilter} 
                paging={paging} 
                changeSort={changeSort} 
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                rowSpan={2}
                title="Shipment ID" 
                prop='shipment_id'
                filter={filter}
                setFilter={changeFilter} 
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <SelectTableHeader 
                rowSpan={2}
                title="Status" 
                prop='status'
                setFilter={changeFilter}
                options={ShipmentStatus} 
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                rowSpan={2}
                title="Donee" 
                prop='donee_name'
                filter={filter}
                setFilter={changeFilter} 
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <td colSpan={3}>Quantities</td>
              <GenericSearchTableHeader 
                rowSpan={2}
                title="Shipper" 
                prop='shipper_name'
                filter={filter}
                setFilter={changeFilter} 
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <td colSpan={5}>Document Dates</td>
              <td colSpan={3}>Estimated Dates</td>
              <td colSpan={3}>Actual Dates</td>
            </tr>
            <tr>
              <GenericSearchTableHeader 
                title="Tablets" 
                prop='total_tablets'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="Bottles" 
                prop='total_bottles'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="$USD" 
                prop='value_usd'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="Certificate" 
                prop='donation_certificate_date'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="Invoice" 
                prop='invoice_date'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="Packing List" 
                prop='ar_packing_list_date'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="Airfreight" 
                prop='airfreight_date'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="Tax Exemp." 
                prop='tax_exemption_date'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="Estimated Departure" 
                prop='estimated_departure_date'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="Estimated Arrival" 
                prop='estimated_arrival_date'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="Estimated Delivery" 
                prop='estimated_delivery_date'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="Actual Departure" 
                prop='actual_departure_date'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="Actual Arrival" 
                prop='actual_delivery_date'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
              <GenericSearchTableHeader 
                title="Actual Delivery" 
                prop='actual_arrival_date'
                paging={paging}
                changeSort={changeSort}
                sortInfo={ShipmentSortInfo} 
              />
            </tr>
          </thead>
          <tbody>
          {
            shipments?.map(row => (
              <tr key={row.id?.toString()}>
                <td scope="row" className="text-nowrap">
                  <Link className="unstyled d-block fw-bold" to={`/shipments?app_id=${row.app_id}`}>{row.app_id}</Link>
                </td>
                <td className="shrink">
                  <Link className="unstyled d-block" to={`/shipments?country_name=${row.country_name}`}>
                    {row.country_name}
                  </Link>
                </td>
                <td className="shrink">
                  <Link className="unstyled d-block" to={`/shipments?request_year=${row.request_year}`}>
                    {row.request_year}
                  </Link>
                </td>
                <td className="shrink">
                  <Link className="unstyled d-block" to={`/shipments?application_status=${row.application_status}`}>
                    <ApplicationStatus status={row.application_status} />
                  </Link>
                </td>
                <td className="shrink fw-bold">
                  <Link className="unstyled d-block" to={`/applications/${row.application_id}/shipments/${row.id}`}>
                    {row.shipment_id}
                  </Link>
                </td>
                <td className="shrink">
                  <Link className="unstyled d-block" to={`/shipments?status=${row.status}`}>
                    {row.status}
                  </Link>
                </td>
                <td className="shrink">
                  <Link className="unstyled d-block" to={`/shipments?donee_name=${row.donee_name}`}>
                    {row.donee_name}
                  </Link>
                </td>
                <td className="shrink treatments"><IntegerView value={row.total_tablets} /></td>
                <td className="shrink treatments"><IntegerView value={row.total_bottles} /></td>
                <td className="shrink treatments"><IntegerView value={row.value_usd} prefix="$" className="fst-italic"/></td>
                <td className="shrink">
                  <Link className="unstyled d-block" to={`/shipments?shipper_name=${row.shipper_name}`}>
                    {row.shipper_name}
                  </Link>
                </td>
                <td className="shrink">{row.donation_certificate_date}</td>
                <td className="shrink">{row.invoice_date}</td>
                <td className="shrink">{row.ar_packing_list_date}</td>
                <td className="shrink">{row.airfreight_date}</td>
                <td className="shrink">{row.tax_exemption_date}</td>
                <td className="shrink">{row.estimated_departure_date}</td>
                <td className="shrink">{row.estimated_arrival_date}</td>
                <td className="shrink">{row.estimated_delivery_date}</td>
                <td className="shrink">{row.actual_departure_date}</td>
                <td className="shrink">{row.actual_arrival_date}</td>
                <td className="shrink">{row.actual_delivery_date}</td>
              </tr>

            ))
          }
          </tbody>
        </table>
      </div>
      </div>

    </>
  )
}
