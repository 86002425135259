import {
  Api,
  DbLiteralTypes,
  DbResponseResultSet,
  PagingParams,
  SortingInfo, 
  WhereBuilder
} from './Api';
import {SessionData} from '../api';
import {FormikValues} from 'formik';


export const ApplicationSortInfo: SortingInfo = {
  app_id: {dataType: 'alpha'},
  status: {dataType: 'alpha'},
  request_year: {dataType: 'num'},
  country_name: {dataType: 'alpha'},
  shipment_ids: {dataType: 'alpha'},
  shipment_statuses: {dataType: 'num'},
  mda1_date: {dataType: 'alpha'},
  mda2_date: {dataType: 'alpha'},
  submitted_date: {dataType: 'alpha'},
  approved_date: {dataType: 'alpha'},
  shipment_arrive_by_date: {dataType: 'alpha'},
  shipment_arrive_by_datez: {dataType: 'alpha'},
  pc_req_ivm_lf_only: {dataType: 'num'},
  pc_req_ivm_oncho_only: {dataType: 'num'},
  pc_req_ivm_lf_oncho: {dataType: 'num'},
  pc_req_ivm_ida: {dataType: 'num'},
  pc_req_ivm_total: {dataType: 'num'},
}

export interface QueryApplicationsFilters {
  app_id: string
  country_name: string
  request_year: string
  status: string,
  shipment_ids: string,
  shipment_statuses: string
  mda1_date?: string
  mda2_date?: string
  submitted_date?: string
  approved_date?: string
  shipment_arrive_by_date?: string
  pc_req_ivm_lf_only?: string
  pc_req_ivm_oncho_only?: string
  pc_req_ivm_lf_oncho?: string
  pc_req_ivm_ida?: string
  pc_req_ivm_total?: string
  scope?: string
}

export const ApplicationStatuses = [
  {value: '', label: ""},
  {value: "not_received", label: "Not Received"},
  {value: "received", label: "Received"},
  {value: "conditional_approval", label: "Conditional Approval"},
  {value: "partial_approval", label: "Partial Approval"},
  {value: "limited_approval", label: "Limited Approval"},
  {value: "approved", label: "Approved"},
  {value: "rejected", label: "Rejected"},
  {value: "shipped", label: "Shipped"},
  {value: "delivered", label: "All Shipments Delivered"},
  {value: "complete", label: "Completed and Closed Out"}
]


const ReadOnlyAttributes = ['app_id_short']


export class ApplicationDao extends Api {
  constructor(sessionData: SessionData) { super(sessionData) }

  public async get(id: number): Promise<{application: FormikValues | undefined, shipmentIds: number[], requestId: number | undefined}> {

    const resp = await Promise.all([
      this.execute("select * from public.applications where id = :id", {id}),
      this.execute("select id from public.shipments where application_id = :id", {id}),
      this.execute("select id from public.requests where application_id = :id", {id}),
    ])
    const appRows = resp[0]
    const shipmentRows = resp[1]
    const requestRows = resp[2]

    return {
      application: this.nullsToEmptyStrings(appRows[0]),
      shipmentIds: shipmentRows.map(r => r.id as number),
      requestId: requestRows.map(r => r.id as number)[0]
    }
  }


  public async save(id: number | undefined, values: FormikValues): Promise<number> {

    let jsonParam = {
      ...this.emptyStringsToNulls(values),
      id,
    }

    ReadOnlyAttributes.forEach((attr) => {
      // @ts-ignore
      delete jsonParam[attr]
    })

    const r = await this.execute("SELECT app.upsert_one('public', 'applications', :json) AS id", {json: JSON.stringify(jsonParam)})

    return r[0].id as number
  }

  public async query(filters: QueryApplicationsFilters, paging: PagingParams): Promise<{rows: DbResponseResultSet, count: number}> {

    const queryParams: Record<string, DbLiteralTypes> = {
      ...filters,
      ...paging,
    }

    const whereBuilder = new WhereBuilder()

    whereBuilder.and(filters.app_id, `LOWER(cte.app_id) LIKE '%' || LOWER(:app_id) || '%'`)
    whereBuilder.and(filters.country_name, `LOWER(cte.country_name) LIKE '%' || LOWER(:country_name) || '%'`)
    whereBuilder.and(filters.request_year, `cte.request_year = :request_year`)
    whereBuilder.and(filters.status, `LOWER(cte.status) LIKE '%' || LOWER(:status) || '%'`)
    whereBuilder.and(filters.shipment_ids, `LOWER(cte.shipment_ids) LIKE '%' || LOWER(:shipment_ids) || '%'`)
    whereBuilder.and(filters.shipment_statuses, `LOWER(cte.shipment_statuses) LIKE '%' || LOWER(:shipment_statuses) || '%'`)
    whereBuilder.and(filters.scope != 'all', `cte.status != 'complete'`)

    const whereClause = whereBuilder.build()

    const cte = `
      SELECT 
        a.id, a.app_id, 
        requests.mda1_date, requests.mda2_date,
        a.submitted_date,
        a.approved_date,
        a.shipment_arrive_by_date,
        c.name AS country_name, a.request_year, a.status::TEXT AS status, 
        array_to_string(array( 
          SELECT s.shipment_id FROM public.shipments s 
          WHERE s.application_id = a.id
        ), ',') AS shipment_ids,
        array_to_string(array( 
          SELECT case
            when actual_delivery_date is not null then 'delivered'
            when actual_arrival_date is not null then 'arrived'
            when actual_departure_date is not null then 'departed'
            else 'in_progress' end
          FROM public.shipments s 
          WHERE s.application_id = a.id 
        ), ',') AS shipment_statuses,
        sum(pc_req_ivm_lf_only) as pc_req_ivm_lf_only,
        sum(pc_req_ivm_oncho_only) as pc_req_ivm_oncho_only,
        sum(pc_req_ivm_lf_oncho) as pc_req_ivm_lf_oncho,
        sum(pc_req_ivm_ida) as pc_req_ivm_ida,
        sum(pc_req_ivm_total) as pc_req_ivm_total
      FROM public.applications a
        JOIN public.countries c ON a.country_id = c.id
        JOIN requests on requests.application_id = a.id
        JOIN request_district_data rdd on rdd.request_id = requests.id
      GROUP BY a.id, a.app_id, requests.mda1_date, requests.mda2_date, c.name, a.submitted_date, a.approved_date, a.shipment_arrive_by_date
    `

    if (!ApplicationSortInfo[paging.sort])
      throw new Error('unknown sorting column') // prevent SQL injection

    const propSortInfo = ApplicationSortInfo[paging.sort]
    const sortColumns = propSortInfo.columns ?? [paging.sort]

    const [rows, countRows] = await Promise.all(
      [
        this.execute(
            `
            WITH cte AS (${cte}) 
            SELECT * 
            FROM cte
            ${whereClause}
            ORDER BY ${sortColumns.map(s => s + ' ' + (paging.order === 'asc' ? '' : 'desc')).join()} 
            LIMIT :limit
            OFFSET :offset
          `, queryParams
        ),
        this.execute(
            `
            WITH cte AS (${cte}) 
            SELECT COUNT(*) 
            FROM cte
            ${whereClause}
          `, queryParams
        ),

      ]
    )

    return {
      rows: rows,
      count: countRows[0].count as number
    }
  }



  public async getCountries(): Promise<{id: number, name: string}[]> {
    return await this.execute(`
        select 
            a.id, a.name
        from public.countries a
        order by a.name
    `) as {id: number, name: string}[]
  }

  public async delete(id: number): Promise<void> {
    await this.execute("DELETE FROM applications WHERE id = :id", {id})
  }

}




