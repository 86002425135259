import {useEffect, useMemo, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {
  ApplicationDao,
  PagingParams, 
  QueryApplicationsFilters, 
  DbResponseResultSet, 
  ApplicationSortInfo,
  DbLiteralTypes
} from '../api';
import {useAppContext} from '../context';
import {inTryCatch} from '../lib';
import {
  ApplicationStatus,
  DangerAlert,
  GenericSearchTableHeader,
  Pager,
  MonthView,
  IntegerView
} from '../components';


export function Applications(): JSX.Element {


  const [searchParams, setSearchParams] = useSearchParams()
  const { session, logout } = useAppContext()
  const [applications, setApplications] = useState<DbResponseResultSet | undefined>()
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [error, showError] = useState<string>()

  const {paging, filter} = useMemo(() => {
    const paging: PagingParams = {
      limit: 50,
      offset: 0,
      sort: 'request_year',
      order: 'desc',
    }

    if (searchParams.get('limit')) paging.limit = parseInt(searchParams.get('limit') ?? '', 10)
    if (searchParams.get('offset')) paging.offset = parseInt(searchParams.get('offset') ?? '', 10)
    if (searchParams.get('sort')) paging.sort = searchParams.get('sort') ?? 'app_id'
    if (searchParams.get('order')) paging.order = searchParams.get('order') === 'asc' ? 'asc' : 'desc'

    const filter: QueryApplicationsFilters = {
      app_id: '',
      country_name: '',
      request_year: '',
      status: '',
      shipment_ids: '',
      shipment_statuses: '',
      scope: ''
    }

    if (searchParams.get('app_id')) filter.app_id = searchParams.get('app_id') ?? ''
    if (searchParams.get('country_name')) filter.country_name = searchParams.get('country_name') ?? ''
    if (searchParams.get('request_year')) filter.request_year = searchParams.get('request_year') ?? ''
    if (searchParams.get('status')) filter.status = searchParams.get('status') ?? ''
    if (searchParams.get('shipment_ids')) filter.shipment_ids = searchParams.get('shipment_ids') ?? ''
    if (searchParams.get('shipment_statuses')) filter.shipment_statuses = searchParams.get('shipment_statuses') ?? ''
    if (searchParams.get('scope')) filter.scope = searchParams.get('scope') ?? ''

    return {paging, filter}
  }, [searchParams])

  function setOffset(offset: number) {
    setSearchParams(prev => {
      prev.set("offset", offset.toString())
      return prev
    }, {replace: true})
  }

  function changeFilter(prop: keyof QueryApplicationsFilters, val: string) {
    setSearchParams(prev => {
      prev.set(prop, val)
      prev.set('offset', '0')
      return prev
    }, {replace: true})
  }

  function changeSort(prop: string) {
    const newDirection = paging.sort === prop
      ? (
        paging.order === 'asc' ? 'desc' : 'asc'
      ) : 'asc'

    setSearchParams(prev => {
      prev.set('order', newDirection)
      prev.set('sort', prop)
      prev.set('offset', '0')
      return prev
    }, {replace: true})
  }

  function shipmentIdsElement(
    shipment_ids: DbLiteralTypes, 
    shipment_statuses: DbLiteralTypes
  ): any {
    if (typeof shipment_ids === 'string' && typeof shipment_statuses === 'string') {
      let el = []
      const shipment_ids_array = shipment_ids.split(',')
      const shipment_statuses_array = shipment_statuses.split(',')
      for (let i = 0; i < shipment_ids_array.length; i++) {
        el.push(
          <div key={shipment_ids_array[i]}>
            {shipment_ids_array[i]} <strong>{shipment_statuses_array[i]}</strong>
          </div>
        )
      }
      return el;
    } else {
      return shipment_ids;
    }
  }

  function shipmentsCount(
    shipment_statuses: DbLiteralTypes,
    status: string
  ): number {
    let statuses = (shipment_statuses || '').toString()
    return statuses.split(',').reduce((total,s) => total + (s === status ? 1 : 0), 0)
  }

  function setScope(scope: string) {
    setSearchParams(prev => {
      prev.set("scope", scope)
      return prev
    }, {replace: true})
  }

  useEffect(inTryCatch(setIsLoading, showError, async(state) => {
      const appsInfo = await new ApplicationDao(session).query(filter, paging)
      if (!state.mounted) return
      setApplications(appsInfo.rows)
      setCount(appsInfo.count)
    }), [paging, filter, session])

  return (
    <>
      <DangerAlert text={error} />
      <div className="row mb-2">
        <div className="col">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button className={`nav-link ${!searchParams.get('scope') || searchParams.get('scope') === '' ? 'active' : ''}`}
                onClick={() => setScope('')}
                title="Any applications not Completed and Closed Out"
              >
                In Progress / Open
              </button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${searchParams.get('scope') === 'all' ? 'active' : ''}`}
                onClick={() => setScope('all')}
                title="All applications"
              >
                All
              </button>
            </li>
          </ul>
        </div>
        <div className="col-md-auto text-md-end">
          <Pager count={count} pager={paging} setOffset={setOffset} />
        </div>
      </div>
      <div className="row">
        <div className="col overflow-auto">
        <table className="table table-striped table-sm table-bordered">
          <thead>
          <tr>
            <GenericSearchTableHeader 
              rowSpan={2}
              title="Application ID" 
              prop='app_id'
              filter={filter}
              setFilter={changeFilter} 
              paging={paging}
              changeSort={changeSort}
              sortInfo={ApplicationSortInfo} 
            />
            <GenericSearchTableHeader
              rowSpan={2}
              title="Country" 
              prop='country_name' 
              filter={filter}
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo} 
            />
            <GenericSearchTableHeader 
              rowSpan={2}
              title="Request Year" 
              prop='request_year' 
              filter={filter}
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo} 
            />
            <GenericSearchTableHeader 
              rowSpan={2}
              title="Application Status" 
              prop='status' 
              filter={filter}
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo}
            />
            <th colSpan={5}>
              IVM Requested
            </th>
            <th colSpan={5}>
              Dates
            </th>
            <th colSpan={4}>
              Shipments
            </th>
{/*            <GenericSearchTableHeader 
              title="Shipments" 
              prop='shipment_ids' 
              filter={filter}
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo} 
            />*/}
            <th scope="col" rowSpan={2}></th>
          </tr>
          <tr>
            <GenericSearchTableHeader 
              title="LF Only" 
              prop='pc_req_ivm_lf_only' 
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo}
            />
            <GenericSearchTableHeader 
              title="Oncho Only" 
              prop='pc_req_ivm_oncho_only' 
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo}
            />
            <GenericSearchTableHeader 
              title="LF/Oncho" 
              prop='pc_req_ivm_lf_oncho' 
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo}
            />
            <GenericSearchTableHeader 
              title="IDA" 
              prop='pc_req_ivm_ida' 
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo}
            />
            <GenericSearchTableHeader 
              title="Total" 
              prop='pc_req_ivm_total' 
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo}
            />
            <GenericSearchTableHeader 
              title="MDA1" 
              prop='mda1_date' 
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo} 
            />
            <GenericSearchTableHeader 
              title="MDA2" 
              prop='mda2_date' 
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo} 
            />
            <GenericSearchTableHeader 
              title="Submitted" 
              prop='submitted_date' 
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo} 
            />
            <GenericSearchTableHeader 
              title="Approved" 
              prop='approved_date' 
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo} 
            />
            <GenericSearchTableHeader 
              title="Arrive By" 
              prop='shipment_arrive_by_date' 
              setFilter={changeFilter} 
              paging={paging} 
              changeSort={changeSort} 
              sortInfo={ApplicationSortInfo} 
            />
            <th scope="col">In Progress</th>
            <th scope="col">Departed</th>
            <th scope="col">Arrived</th>
            <th scope="col">Delivered</th>
          </tr>
          </thead>
          <tbody>
          {
            applications?.map(row => (
              <tr key={row.app_id?.toString()}>
                <td scope="row" className="text-nowrap">
                  <Link className="unstyled d-block fw-bold" to={`/applications/${row.id}`}>{row.app_id}</Link>
                </td>
                <td className="shrink">
                  <Link className="unstyled d-block" to={`/?country_name=${row.country_name}`}>
                    {row.country_name}
                  </Link>
                </td>
                <td className="shrink">
                  <Link className="unstyled d-block" to={`/?request_year=${row.request_year}`}>
                    {row.request_year}
                  </Link>
                </td>
                <td className="shrink">
                  <Link className="unstyled d-block" to={`/?status=${row.status}`}>
                    <ApplicationStatus status={row.status} />
                  </Link>
                </td>
                <td className="shrink treatments"><IntegerView value={row.pc_req_ivm_lf_only} /></td>
                <td className="shrink treatments"><IntegerView value={row.pc_req_ivm_oncho_only} /></td>
                <td className="shrink treatments"><IntegerView value={row.pc_req_ivm_lf_oncho} /></td>
                <td className="shrink treatments"><IntegerView value={row.pc_req_ivm_ida} /></td>
                <td className="shrink treatments-total"><IntegerView value={row.pc_req_ivm_total} /></td>
                <td className="shrink"><MonthView value={row.mda1_date} /></td>
                <td className="shrink"><MonthView value={row.mda2_date} /></td>
                <td className="shrink">{row.submitted_date}</td>
                <td className="shrink">{row.approved_date}</td>
                <td className="shrink">{row.shipment_arrive_by_date}</td>
                {/* <td>{shipmentIdsElement(row.shipment_ids, row.shipment_statuses)}</td> */}
                <td className="shrink shipstatus">
                  <Link className="unstyled d-block" 
                    to={`/shipments?scope=all&status=in_progress&app_id=${row.app_id}`}
                  >
                    {shipmentsCount(row.shipment_statuses,'in_progress')}
                  </Link>
                </td>
                <td className="shrink shipstatus">
                  <Link className="unstyled d-block" 
                    to={`/shipments?scope=all&status=departed&app_id=${row.app_id}`}
                  >
                    {shipmentsCount(row.shipment_statuses,'departed')}
                  </Link>
                </td>
                <td className="shrink shipstatus">
                  <Link className="unstyled d-block" 
                    to={`/shipments?scope=all&status=arrived&app_id=${row.app_id}`}
                  >
                    {shipmentsCount(row.shipment_statuses,'arrived')}
                  </Link>
                </td>
                <td className="shrink shipstatus">
                  <Link className="unstyled d-block" 
                    to={`/shipments?scope=all&status=delivered&app_id=${row.app_id}`}
                  >
                    {shipmentsCount(row.shipment_statuses,'delivered')}
                  </Link>
                </td>
                <td><Link className="btn btn-outline-primary btn-tiny" to={`/applications/${row.id}`}>view</Link></td>
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
      </div>
    </>

  )
}
