import {CellObject, WorkBook, WorkSheet} from 'xlsx'
import { compact, concat } from 'lodash'
import {
  JRSMParseResult,
  JRSMParseResultSection,
  JRSMParseResultField,
  JRSMParserConfig,
  JRSMParserSection,
  JRSMParserField,
  ParserFunction,
  ResultValue
} from './types'
import { default as jrsm2018 } from './jrsm2018'
import jrsm2018LoaLoa from './jrsm2018LoaLoa';
import jrsmv40 from './jrsmv40';
import jrsmv40LoaLoa from './jrsmv40LoaLoa';


// Gets the sheet from the workbook via name, which could be a string or RegExp
const getSheet = ({
  workbook,
  name
}: {
  workbook: WorkBook, 
  name: string|RegExp
}): WorkSheet|null => {
  const sheetNames = workbook.SheetNames
  for (let i = 0; i < sheetNames.length; i++) {
    const sheetName = sheetNames[i]
    if (
      (typeof name === 'string' && name === sheetName) ||
      (typeof name === 'object' && sheetName.match(name))
    ) {
      return workbook.Sheets[sheetName]
    }
  }
  return null;
}

// Get the parsed value of the field for the parser field in the workbook
const getFieldValue = (
  workbook:WorkBook, 
  parserField: JRSMParserField, 
  fields: JRSMParseResultField[],
  row?: number
): JRSMParseResultField|null => {
  if (parserField.value) {
    return {
      name: parserField.name,
      value: parserField.value,
      db: parserField.db
    }
  } else if (parserField.cell) {
    const worksheet = getSheet({workbook, name: parserField.sheet})
    if (!parserField.optional && !worksheet) {
      throw new Error(`Unable to find sheet matching ${parserField.sheet}`)
    }
    if (worksheet) {
      const cell = worksheet[parserField.cell]
      return {
        name: parserField.name,
        value: parserField.parser ? parserField.parser(cell?.v) : cell?.v,
        db: parserField.db
      }
    } else {
      return null;
    }
  } else if (typeof row !== 'undefined' && parserField.column) {
    const worksheet = getSheet({workbook, name: parserField.sheet})
    if (!parserField.optional && !worksheet) {
      throw new Error(`Unable to find sheet matching ${parserField.sheet}`)
    }
    if (worksheet) {
      const cell = worksheet[parserField.column+row]
      return {
        name: parserField.name,
        value: parserField.parser ? parserField.parser(cell?.v) : cell?.v,
        db: parserField.db
      } 
    } else {
      return null;
    }
  } else if (parserField.mapper) {
    return {
      name: parserField.name,
      value: parserField.mapper(fields),
      db: parserField.db
    }
  }
  throw new Error('this shouldn\'t happen ' + JSON.stringify(parserField) + ', row=' + JSON.stringify(row))
}


const parseSection = (
  workbook: WorkBook, 
  parserSection: JRSMParserSection
): JRSMParseResultSection[] => {
  let results = []

  if (typeof parserSection.startRow !== 'undefined') {
    const sheetName = parserSection.fields[0].sheet;
    const firstSheet = getSheet({workbook, name: sheetName})
    if (!firstSheet) {
      throw new Error(`Unable to find sheet matching ${sheetName}`)
    }
    const range = /([A-Z]+)([\d]+):([A-Z]+)([\d]+)/.exec(firstSheet['!ref'] || '')
    const startRow = parserSection.startRow
    const endRow = parserSection.endRow || parseInt(range ? range[4] : '500', 10);
    for (let row = startRow; row <= endRow; row++) {
      let fields:JRSMParseResultField[] = []
      parserSection.fields.forEach((field) => {
        const parsedField = getFieldValue(workbook, field, fields, row)
        if (parsedField) fields.push(parsedField)
      })
      if (fields.filter(f => f.value !== null && typeof f.value !== 'undefined').length > 0) {
        if ( 
          (parserSection.filter && parserSection.filter(fields)) ||
          !parserSection.filter
        ) {
          results.push({ fields })
        }
      } else {
        break;
      }
    }
  } else {
    let fields:JRSMParseResultField[] = []
    parserSection.fields.forEach((field) => {
      const parsedField = getFieldValue(workbook, field, fields)
      if (parsedField) fields.push(parsedField)
    })
    if (fields.filter(f => f.value !== null && typeof f.value !== 'undefined').length > 0) {
      if (
        (parserSection.filter && parserSection.filter(fields)) ||
        !parserSection.filter
      ) {
        results.push({fields})
      }
    }
  }
  return results
}

const mergeParserResults = (
  workbook:WorkBook,
  sections:JRSMParserSection[]
): JRSMParseResultSection[] => {
  let resultSections:JRSMParseResultSection[] = []
  sections.forEach(section => {
    resultSections = concat(resultSections, parseSection(workbook, section))
  })
  let sectionFields:JRSMParseResultField[] = []
  resultSections.forEach(({fields}) => {
    sectionFields = concat(sectionFields, fields || [])
  })
  return [{fields: sectionFields}]
}

const concatParserResults = (
  workbook:WorkBook,
  sections:JRSMParserSection[]
): JRSMParseResultSection[] => {
  let resultSections:JRSMParseResultSection[] = []
  sections.forEach(section => {
    resultSections = concat(resultSections, parseSection(workbook, section))
  })
  return resultSections
}

const parseRequestDistrictData = (
  workbook: WorkBook, 
  parserConfig: JRSMParserConfig
): JRSMParseResultSection[] => {
  return parseSection(workbook, parserConfig.request_district_data)
}

const parseRequestData = (
  workbook: WorkBook,
  parserConfig: JRSMParserConfig
): JRSMParseResultSection[] => {
  return mergeParserResults(workbook, [
    parserConfig.requests_ivm_data,
    parserConfig.requests_shipment_data,
    parserConfig.requests_to_treat_lf,
    parserConfig.requests_to_treat_oncho,
    parserConfig.requests_dates
  ])
}

const parseRequestContacts = (
  workbook: WorkBook,
  parserConfig: JRSMParserConfig
): JRSMParseResultSection[] => {
  return concatParserResults(workbook, [
    parserConfig.request_contacts_1,
    parserConfig.request_contacts_2,
    parserConfig.request_contacts_3,
    parserConfig.request_contacts_4,
    parserConfig.request_contacts_5,
    parserConfig.request_contacts_6,
  ])
}

function detectWorkbookType(workbook: WorkBook): JRSMParserConfig {
  const sheet = getSheet({workbook, name: /INTRO/})
  if (!sheet) {
    throw new Error(`Unable to find sheet matching /INTRO/`)
  }
  const cell: CellObject = sheet['B2'];
  const cellValue = cell.v ? cell.v.toString() : "";
  if (/v.3/.test(cellValue) && /\(L/.test(cellValue)) {
    return jrsm2018LoaLoa;
  } else if (/v.3/.test(cellValue)) {
    return jrsm2018;
  } else if (/v.4/.test(cellValue) && /\(L/.test(cellValue)) {
    return jrsmv40LoaLoa;
  } else if (/v.4/.test(cellValue)) {
    return jrsmv40;
  } else {
    throw new Error('Unknown worksheet type');
  }
}

export const parseJRSM = (workbook: WorkBook): JRSMParseResult => {

  const parserConfig = detectWorkbookType(workbook)

  return {
    request_district_data: parseRequestDistrictData(workbook, parserConfig),
    requests: parseRequestData(workbook, parserConfig),
    request_contacts: parseRequestContacts(workbook, parserConfig),
    request_contributors: parseSection(workbook, parserConfig.request_contributors),
    request_funding: parseSection(workbook, parserConfig.request_funding),
    request_not_targeted: parseSection(workbook, parserConfig.request_not_targeted)
  }

}
