import {Api, DbLiteralTypes, DbResponseResultSet, PagingParams, SortingInfo, WhereBuilder} from './Api';
import {SessionData} from './Session';

export const RequestDistrictSortInfo: SortingInfo = {
  district: { dataType: 'alpha', columns: ["COALESCE(region_name, '')", 'subregion_name', 'district_name', 'jrsm_region_name', 'jrsm_district_name'] },
  pc_req_ivm_lf_only: { dataType: 'num' },
  pc_req_ivm_oncho_only: { dataType: 'num' },
  pc_req_ivm_lf_oncho: { dataType: 'num' },
  pc_req_ivm_ida: { dataType: 'num' },
  pc_req_ivm_total: { dataType: 'num' },
  pc_req_ivm_to_procure: { dataType: 'num' },
  population_presac: { dataType: 'num' },
  population_sac: { dataType: 'num' },
  population_adult: { dataType: 'num' },
  endemicity_lf: { dataType: 'alpha' },
  endemicity_oncho: { dataType: 'alpha' },
  endemicity_loaloa: { dataType: 'alpha' },
  rounds_lf: { dataType: 'num' },
  rounds_oncho: { dataType: 'num' },
  surveys_lf_tas: { dataType: 'alpha' },
  surveys_oncho: { dataType: 'alpha' },
  target_pop_village_oncho: { dataType: 'num' },
  ivm_stock_remaining: { dataType: 'num' },
}


export const Endemicity = ['non_endemic', 'endemic', 'status_unknown', 'endemic_mda_stopped', 'endemic_pending_impact_survey']
type EndemicityType = typeof Endemicity[number]

export interface QueryRequestDistrictFilters {
  district: string
  endemicity_lf?: EndemicityType
  endemicity_oncho?: EndemicityType
  endemicity_loaloa?: EndemicityType
}


export class RequestDistrictDataDao extends Api {
  constructor(sessionData: SessionData) { super(sessionData) }




  public async query(requestId: number, filters?: QueryRequestDistrictFilters, pagingParam?: PagingParams): Promise<{rows: DbResponseResultSet, count: number}> {

    filters ??= {
      district: ''
    }

    const paging = pagingParam ?? {
      sort: 'district',
      offset: 0,
      order: 'asc',
      limit: 1000000
    }

    const queryParams: Record<string, DbLiteralTypes> = {
      ...filters,
      ...paging,
      requestId,
    }

    const whereBuilder = new WhereBuilder()

    whereBuilder.and(filters.endemicity_lf, `cte.endemicity_lf = :endemicity_lf`)
    whereBuilder.and(filters.endemicity_oncho, `cte.endemicity_oncho = :endemicity_oncho`)
    whereBuilder.and(filters.endemicity_loaloa, `cte.endemicity_loaloa = :endemicity_loaloa`)
    whereBuilder.and(filters.district, `LOWER(cte.filter_district) LIKE '%' || LOWER(:district) || '%'`)

    const whereClause = whereBuilder.build()

    const cte = `
      SELECT 
        rd.*,
        r.name AS region_name,
        sr.name AS subregion_name,
        d.name AS district_name,
        COALESCE
            (r.name || ' -> ' || COALESCE(sr.name || ' -> ', '') || d.name,
             rd.jrsm_region_name || ' -> ' || rd.jrsm_district_name) AS filter_district
      FROM public.request_district_data rd
      LEFT JOIN public.districts d ON rd.district_id = d.id
      LEFT JOIN public.subregions sr ON d.subregion_id = sr.id
      LEFT JOIN public.regions r ON COALESCE(sr.region_id, d.region_id) = r.id
      WHERE rd.request_id = :requestId
    `


    if (!RequestDistrictSortInfo[paging.sort])
      throw new Error('unknown sorting column') // prevent SQL injection

    const propSortInfo = RequestDistrictSortInfo[paging.sort]
    const sortColumns = propSortInfo.columns ?? [paging.sort]

    const [rows, countRows] = await Promise.all(
      [
        this.execute(
          `
            WITH cte AS (${cte}) 
            SELECT * 
            FROM cte
            ${whereClause}
            ORDER BY ${sortColumns.map(s => s + ' ' + (paging.order === 'asc' ? '' : 'desc')).join()}
            LIMIT :limit
            OFFSET :offset
          `, queryParams
        ),
        this.execute(
          `
            WITH cte AS (${cte}) 
            SELECT COUNT(*) 
            FROM cte
            ${whereClause}
          `, queryParams
        ),

      ]
    )

    return {
      rows: rows,
      count: countRows[0].count as number
    }
  }

  async queryDistricts(param: {requestId: number, filter: string} | {applicationId: number}): Promise<DbResponseResultSet> {

    const countrySql = 'requestId' in param
      ? `  SELECT a.country_id 
            FROM public.requests rq 
                JOIN public.applications a ON rq.application_id = a.id
            WHERE rq.id = :requestId`
      : `  SELECT a.country_id 
            FROM public.applications a 
            WHERE a.id = :applicationId`

    const filterSql = 'filter' in param
      ? `  AND LOWER(CASE WHEN sr.id IS NULL
                THEN r.name || ' -> ' || d.name
                ELSE r.name || ' -> ' || sr.name || ' -> ' || d.name
              END) LIKE '%' || LOWER(:filter) || '%' `
      : ''

    return await this.execute(
      `
          SELECT
              r.id AS region_id,
              sr.id AS subregion_id,
              d.id,
              r.name AS region_name,
              sr.name AS subregion_name,
              d.name AS district_name,
              r.alt_names AS region_alt_names,
              d.alt_names AS district_alt_names,
              sr.alt_names AS subregion_alt_names,
              r.country_id,
              CASE WHEN sr.id IS NULL
                THEN r.name || ' -> ' || d.name
                ELSE r.name || ' -> ' || sr.name || ' -> ' || d.name
              END AS full_name
          FROM public.districts d 
                   LEFT JOIN public.subregions sr ON d.subregion_id = sr.id
                   LEFT JOIN public.regions r ON COALESCE(sr.region_id, d.region_id) = r.id
          WHERE r.country_id IN (
            ${countrySql}
          ) 
          ${filterSql}  
          AND d.deleted_at IS NULL
          `, param
    )
  }



  async assignDistrict(id: number, request_id: number, district_id: number): Promise<void> {
    await this.execute("SELECT app.upsert_one('public', 'request_district_data', :json) AS id", {json: JSON.stringify({id, request_id, district_id})})
  }
}
