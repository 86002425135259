const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


export function MonthView({
  value
}: {
  value: any
}): JSX.Element {

  let date = '';
  if (value) {
    let d = new Date(value);
    date = `${months[d.getMonth()]} ${d.getFullYear()}`
  }
  return (
    <>{date}</>
  );
}
