import {Api} from './Api';
import {SessionData} from './Session';
import {FormikValues} from 'formik';
import {FileData} from '../components/form';

export class ApplicationApprovalDao extends Api {

  constructor(sessionData: SessionData) { super(sessionData) }


  public async get(application_id: number | string): Promise<FormikValues | undefined> {
    const [
      rows,
      request
    ] = await Promise.all([
      this.execute(`
        select 
            a.*,            
            alf.file_name   AS approval_letter_file_name,
            alf.mime_type   AS approval_letter_mime_type,
            alf.id          AS approval_letter_file_id,
            alf.upload_date AS approval_letter_file_upload_date,            
            alef.file_name      AS approval_letter_email_file_name,
            alef.mime_type      AS approval_letter_email_mime_type,
            alef.id             AS approval_letter_email_file_id,
            alef.upload_date    AS approval_letter_email_file_upload_date,            
            af.file_name    AS acknowledgement_file_name,
            af.mime_type    AS acknowledgement_mime_type,
            af.id           AS acknowledgement_file_id,
            af.upload_date  AS acknowledgement_file_upload_date
        from public.application_approvals a
        left join public.files alf ON a.approval_letter_file_id = alf.id
        left join public.files alef ON a.approval_letter_email_file_id = alef.id
        left join public.files af ON a.acknowledgement_file_id = af.id
        where a.application_id = :application_id
    `, {application_id}),
    this.execute(`
      select
        sum(pc_req_ivm_lf_only) as pc_req_ivm_lf_only,
        sum(pc_req_ivm_oncho_only) as pc_req_ivm_oncho_only,
        sum(pc_req_ivm_lf_oncho) as pc_req_ivm_lf_oncho,
        sum(pc_req_ivm_ida) as pc_req_ivm_ida,
        sum(pc_req_ivm_total) as pc_req_ivm_total
      from request_district_data
        join requests on requests.id = request_district_data.request_id
      where requests.application_id = :application_id
    `, {application_id})
    ]);
    return {
      rows: this.nullsToEmptyStrings(rows[0]),
      request: this.nullsToEmptyStrings(request[0])
    }
  }

  public async save(values: FormikValues, application_id: number, approval_letter_file: FileData | undefined, approval_letter_email_file: FileData | undefined, acknowledgement_file: FileData | undefined): Promise<number> {

    const jsonParam = {
      applicationApproval : {
        ...this.emptyStringsToNulls(values),
        application_id,
      },
      approval_letter_file: await this.toDbFile(approval_letter_file),
      approval_letter_email_file: await this.toDbFile(approval_letter_email_file),
      acknowledgement_file: await this.toDbFile(acknowledgement_file),
    }

    const r = await this.execute('SELECT app.saveApplicationApproval(:json) AS id', {json: JSON.stringify(jsonParam)})

    return r[0].id as number
  }
}
